'use client';

import { useAtom, useAtomValue } from 'jotai';
import React, { createElement, useRef, useState } from 'react';
import classNames from 'classnames';
import contactModalTriggerButton, { contactExpert, defaultContactExpert } from '../store/contactForm';
import styles from './ContactModal.module.scss';
import Button, { ButtonVariants } from './Button';
import Form from './Form/Form';
import { FormComponentData } from './Form/interfaces';
import { useOutsideClick } from '../useOutsideClick';
import Img from './Img';
import SocialLink from './SocialLink';
import AnimatedTitle from './AnimatedTitle';
import { GAEmit, GAEvents } from '../gtagHelper';

const ContactModal = ({ isBlockView, titleComponent = 'h3' }: { isBlockView?: boolean; titleComponent?: 'h1' | 'h3' }) => {
  const [contactModalTrigger, setContactsModalTrigger] = useAtom(contactModalTriggerButton);
  const currentExpert = useAtomValue(contactExpert);
  const [defaultExpert] = useAtom(defaultContactExpert);

  const expert = currentExpert || defaultExpert;

  const [nameValue, setNameValue] = useState<string>('');
  const [emailValue, setEmailValue] = useState<string>('');
  const [messageValue, setMessageValue] = useState<string>('');

  const modalRef = useRef<HTMLDivElement | null>(null);

  const handleClose = () => {
    setContactsModalTrigger(null);
  };

  useOutsideClick(modalRef, handleClose);

  const clearForm = () => {
    setNameValue('');
    setEmailValue('');
    setMessageValue('');
  };

  const handleOpenCalendly = () => {
    GAEmit(GAEvents.bookACallClick);
    window.open(expert?.expertData.calendly, '_blank');
  };

  const handleLinkedInClick = () => {
    GAEmit(GAEvents.linkedInClick);
  };

  const formData: FormComponentData = {
    title: 'UNLEASH YOUR **IDEA**',
    titleClassName: expert ? styles.contactTitle : '',
    description: '',
    fields: [
      {
        id: 'nameField',
        name: 'name',
        type: 'input',
        inputType: 'text',
        label: 'Enter your name',
        value: nameValue,
        setValue: setNameValue,
        errors: {
          required: 'Please enter your name',
        },
      },
      {
        id: 'emailField',
        name: 'email',
        type: 'input',
        inputType: 'email',
        label: 'Enter your email',
        value: emailValue,
        setValue: setEmailValue,
        errors: {
          required: 'Please provide your email address',
          custom: [{
            condition: emailValue !== '' && !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(emailValue),
            value: 'Please enter a valid email',
          }],
        },
      },
      {
        id: 'projectDescriptionField',
        name: 'projectDescription',
        type: 'textarea',
        label: 'Tell us about your project',
        value: messageValue,
        setValue: setMessageValue,
      },
    ],
    submitData: {
      endpoint: 'contact-form-lead',
      value: {
        name: nameValue,
        email: emailValue,
        message: messageValue,
        clickedButton: contactModalTrigger || undefined,
      },
      successCallback: () => GAEmit(GAEvents.contactFormSubmission),
    },
    openedModal: contactModalTriggerButton,
  };

  const expertWithSocials = !!expert?.expertData.linkedin;

  return ( // eslint-disable-next-line
    <>
      {(contactModalTrigger || isBlockView) && (
      <div
        className={classNames(styles.modalOverlay, {
          [styles.blockView]: isBlockView,
        })}
      >
        <div
          ref={modalRef}
          className={classNames(styles.contactModal, {
            [styles.blockView]: isBlockView,
          })}
        >
          {createElement(
            titleComponent,
            { className: classNames(styles.mobileTitle) },
            <AnimatedTitle title='UNLEASH YOUR **IDEA**' />,
          )}
          {expert ? (
            <section className={styles.modalBody}>
              <div className={styles.expertWrapper}>
                <div
                  className={styles.contactExpert}
                  style={{ backgroundImage: expert.expertData.avatar.url }}
                >
                  <Img
                    src={expert.expertData.avatar.url}
                    alt={expert.expertData.avatar.alternativeText || 'contact expert'}
                    className={classNames(styles.expertAvatar, styles.desktop)}
                    priority
                    withPreloader
                  />
                  {expertWithSocials && (
                  <div className={classNames(styles.socials, styles.desktop)}>
                    {expert?.expertData?.linkedin && (
                    <SocialLink
                      id='linkedinSVG'
                      icon='/media/socials/linkedin.svg'
                      link={expert?.expertData?.linkedin}
                      className={styles.socialLink}
                      onClick={handleLinkedInClick}
                    />
                    )}
                  </div>
                  )}
                  <div
                    className={styles.expertInfoWrapper}
                    style={{ backgroundImage: `url(${expert.expertData.avatar.url})` }}
                  >
                    <div
                      className={styles.expertInfo}
                    >
                      <div className={styles.expertDescriptionWrapper}>
                        <Img
                          src={expert.expertData.avatar.url}
                          alt={expert.expertData.avatar.alternativeText || 'contact expert'}
                          className={classNames(styles.expertAvatar, styles.mobile)}
                          priority
                          withPreloader
                        />
                        <div className={styles.expertDescription}>
                          <h6 className={classNames(styles.title, 'title6')}>{expert?.pseudonym}</h6>
                          <p className={classNames(styles.expertDescriptionText, styles.mobileHide)}>{expert?.expertData?.description}</p>
                          <div className={classNames(styles.actions, styles.mobile)}>
                            {expertWithSocials && (
                            <div className={classNames(styles.socials)}>
                              {expert?.expertData?.linkedin && (
                              <SocialLink
                                id='linkedinSVG'
                                icon='/media/socials/linkedin.svg'
                                link={expert?.expertData?.linkedin}
                                className={styles.socialLink}
                                onClick={handleLinkedInClick}
                              />
                              )}
                            </div>
                            )}
                            <Button
                              variant={ButtonVariants.OUTLINED}
                              onClick={handleOpenCalendly}
                              className={styles.bookACallButton}
                            >
                              Book a call
                            </Button>
                          </div>
                        </div>
                      </div>
                      <div className={classNames(styles.actions, styles.desktop)}>
                        <div className={classNames(styles.socials, styles.mobile)}>
                          {expert?.expertData?.linkedin && (
                          <SocialLink
                            id='linkedinSVG'
                            icon='/media/socials/linkedin.svg'
                            link={expert?.expertData?.linkedin}
                            className={styles.socialLink}
                            onClick={handleLinkedInClick}
                          />
                          )}
                        </div>
                        <Button
                          variant={ButtonVariants.OUTLINED}
                          className={classNames(styles.mobileHide, styles.bookACallButton)}
                          onClick={handleOpenCalendly}
                        >
                          Book a call
                        </Button>
                      </div>
                      <p className={classNames(styles.expertDescriptionText, styles.mobileOnly)}>{expert?.expertData?.description}</p>
                    </div>
                  </div>
                </div>
              </div>
              <Form
                modalView
                data={formData}
                onSuccessSubmit={clearForm}
                className={styles.formWrapper}
                formClassName={styles.customForm}
                noBackground
                contentView='column'
                submitButtonView={ButtonVariants.PRIMARY}
                enableSubmitOnInvalid
                titleComponent={titleComponent}
              />
            </section>
          ) : (
            <Form
              modalView
              data={formData}
              onSuccessSubmit={clearForm}
              className={styles.oldFormWrapper}
              formClassName={styles.customForm}
              submitButtonView={ButtonVariants.PRIMARY}
              enableSubmitOnInvalid
              titleComponent={titleComponent}
            />
          )}
          {!isBlockView && (
          <button
            type='button'
            className={styles.closeButton}
            onClick={handleClose}
          >
            <svg>
              <use
                xlinkHref='/media/menuClose.svg#menuCloseSVG'
                href='/media/menuClose.svg#menuCloseSVG'
              />
            </svg>
          </button>
          )}
          <div className={styles.modalFooter}>
            <p className={styles.stepsTitle}>Next Steps After Form Submission:</p>
            <div className={styles.submitSteps}>
              <div className={styles.submitStep}>
                <span className={styles.number}>1</span>
                <p className={styles.stepDescription}>
                  <b className={styles.stepTitle}>
                    Quick Contact:
                    {' '}
                  </b>
                  We will reach out within 24 hours
                </p>
              </div>
              <div className={styles.submitStep}>
                <span className={styles.number}>2</span>
                <p className={styles.stepDescription}>
                  <b className={styles.stepTitle}>
                    Free Consultation:
                    {' '}
                  </b>
                  Enjoy a 1-hour consultation with our expert
                </p>
              </div>
              <div className={styles.submitStep}>
                <span className={styles.number}>3</span>
                <p className={styles.stepDescription}>
                  <b className={styles.stepTitle}>
                    Detailed Quote:
                    {' '}
                  </b>
                  Receive a quote within 5 days
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      )}
    </>
  );
};

export default ContactModal;
